import React, { Component } from 'react'
import CardPlayerItemRender from './generic/CardPlayerItemRender';
import UsersWinner from './generic/UsersWinner';
import { Link } from 'react-router-dom';

export class Winner extends Component {
    
    render() {
        const tablasUsers = this.props.winnersInfo.map((cartaEnJuego)=>{
            return(
                 <CardPlayerItemRender  showWinner={true}  key={cartaEnJuego.id} cartaData={cartaEnJuego}></CardPlayerItemRender>
              )
          })
        return (
          
            <div id="UIGanador" className="clearfix">
                <p className="labelGanador">
                ¡ FELICIDADES ! GANADOR
                </p>
                <div id="auto" className="clearfix">
                    {tablasUsers}
                </div>
                <div style={{width:'100%'}}>
                    <div  style={{margin: '0 auto', width:'400px'}}>
                        <input onClick={this.props.onUndoEvent} className="Botones" style={{ float:'left', marginRight:'40px', backgroundColor:'#f1d231', borderColor:'#f1d231'}} id="input" type="button" defaultValue="DESHACER" />
                       

                        <input onClick={this.props.onNewGameEvent} className="Botones" style={{ float:'left', backgroundColor:'#f1d231', borderColor:'#f1d231'}} id="input" type="button" defaultValue="NUEVO JUEGO" />
                      

                    </div>
                </div>
            </div>
        )
    }
}

export default Winner
